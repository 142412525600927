import React, { Component } from 'react';
import logo from './logo.svg';
import './App.scss';
import {Text} from 'office-ui-fabric-react/lib/Text';

import {Polar} from 'react-chartjs-2';

import Impact from './Criteria/Impact';
import Urgency from './Criteria/Urgency'
import Importance from './Criteria/Importance'
import Skill from './Criteria/Skill'
import Command from './Command'

class App extends Component {

    state = {
        data: [1, 1, 1, 1],
        active: false,
        score: 0,
        slaLevel: 'Normal'
        
    }

    handleChange = (index, value) => {
        const newData = [...this.state.data]
        newData[index] = value

        let score = 0

        // Applications Affected
        if (newData[0] === 1) {
            score = 0.0625
        }
        if (newData[0] === 2) {
            score = 0.125
        }
        if (newData[0] === 3) {
            score = 0.25
        }
        if (newData[0] === 4) {
            score = 0.5
        }

        // Users Affected
        if (newData[1] === 1) {
            score = score * 0.125
        }
        if (newData[1] === 2) {
            score = score * 0.5
        }
        if (newData[1] === 3) {
            score = score * 1
        }
        if (newData[1] === 4) {
            score = score * 2
        }

        // Organisation Size 
        if (newData[2] === 1) {
            score = score * 1.125
        }
        if (newData[2] === 2) {
            score = score * 1.25
        }
        if (newData[2] === 3) {
            score = score * 1.5
        }
        if (newData[2] === 4) {
            score = score * 2
        }

        // Organisation Size 
        if (newData[3] === 1) {
            score = score * 0.5
        }
        if (newData[3] === 2) {
            score = score * 1
        }
        if (newData[3] === 3) {
            score = score * 2.5
        }
        if (newData[3] === 4) {
            score = score * 4
        }
        if (score > 1) {
            score = 1
        }
        let slaLevel = ``
        if (score >= 0.2) {
            slaLevel = `High`
        }
        if (score >= 0.5) {
            slaLevel = `Urgent`
        }
        if (score >= 0.9) {
            slaLevel = `Emergency`
        }
        if (score < 0.2) {
            slaLevel = `Normal`
        }
        
        
        this.setState({
            data: [...newData],
            active: true,
            score,
            slaLevel
        })
    }

    render() {

        const data = {
            datasets: [{
              data: this.state.data,
              backgroundColor: [
                'rgba(255,99,132, 1)',
                'rgba(255,206,86, 1)',
                'rgba(75,192,192, 1)',
                'rgba(54,162,235, 1)',
                // 'rgba(231,233,237, 1)',
              ],
            }],
            labels: [
              'Impact',
              'Urgency',
              'Importance',
              'Resources',
            //   'SLA Level',
            ]
        };

        return (
            <div>
                <div className="body">
                    <div className="header">
                        IT Dynamics
                    </div>
                    {/* <Command/> */}
                    <div className="content">
                        <div className="left">
                            <div className="top">
                                <Text
                                    block
                                    style={{
                                    fontSize: 36
                                }}>
                                    SLA Impact Matrix
                                </Text>
                                <Text block>
                                    Calculate the priority of tasks by defining impact, urgency and important for
                                    situations and companies.
                                </Text>
                            </div>
                            <Impact handleChange={this.handleChange} data={this.state.data} />
                            <Urgency handleChange={this.handleChange} data={this.state.data} />
                            <Importance handleChange={this.handleChange} data={this.state.data} />
                            <Skill handleChange={this.handleChange} data={this.state.data} />
                        </div>
                        <div className={`right ${this.state.active ? "active" : ""}`}>
                            <CompleteQuestionPlaceholder
                                score={this.state.score}
                                slaLevel={this.state.slaLevel}
                                active={this.state.active}
                                data={data} />
                        </div>
                    </div>
                </div>
            </div>
         );
    }
}

const CompleteQuestionPlaceholder = ({ active, data,score, slaLevel }) =>
<div className="complete-questions">
        {active ?
            <div >
            <Text style={{
                    fontSize: 36,
                }} block>
                    {slaLevel}
            </Text>
            <Text style={{
                    fontSize: 70,
                    marginBottom: 30
                }} block>
                    {(score*100).toFixed(2)}%
            </Text>
            <Text style={{
                    fontSize: 24,
                    
                    marginBottom: '1em'
                }} block>SLA Calculation</Text>
                <div style={{marginBottom:30}}>
                    <Polar
                        options={{
                            scale: {
                                ticks: {
                                    beginAtZero: true,
                                    max: 4,
                                    min: 0,
                                    stepSize: 1
                                }
                            }
                        }}
                        data={data}
                        legend={{ position: 'bottom' }} />
                </div>
                {slaLevel === "Normal" && <Normal />}
                {slaLevel === "High" && <High />}
                {slaLevel === "Urgent" && <Urgent />}
                {slaLevel === "Emergency" && <Emergency />}
            </div>
:
<div>
        <img src={require('./undraw_checking_boxes_2ibd.svg')} alt="Check" width="66%" />
	<Text style={{
		fontSize: 24,
		marginTop: '1em'
    }} block>Complete the questions</Text>
    </div>
    }
</div>

const Logo = () => <div>
    <img src={require('./logo.png')} alt="IT Dynamics" width="360"/>
</div>

const Normal = () =>
    <div>
        General questions, enquires, or problems that do not affect any users ability to work.
        <div><h4>Examples</h4></div>
        <div>How do I do something?</div>
    </div>

const High = () =>
<div>
    10% users unable to perform a single function or experiencing inconvenience.
    <div><h4>Examples</h4></div>
    <div>Application faults</div>
    <div>File/s unavailable</div>
    </div>

const Urgent = () =>
<div>
    20% or more users are uanble to work or all users greatly inconvenienced.
    <div><h4>Examples</h4></div>
    <div>Single Virus</div>
    <div>Users machine crashed</div>
    <div>Internet Outage</div>
    <div>File server offline</div>
    </div>

const Emergency = () =>
<div>
    All users at a site unable to work.
    <div><h4>Examples</h4></div>
    <div>Virus outbreak</div>
    <div>Email server failure</div>
    <div>Sever Crash</div>
    <div>Network Failure</div>
</div>

export default App;
