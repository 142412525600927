import React, { Component } from 'react';
import { ChoiceGroup, IChoiceGroupOption } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { Text } from 'office-ui-fabric-react/lib/Text';

class Step1Impact extends Component {
	state = {
		selectedKey: 'bar'
	}
	onChange = (ev, option) => {
		this.setState({
			selectedKey: option.key
		});
		this.props.handleChange(1,option.key)
	}
	render() {
		return (
			<div>
				<Text variant="Large" block style={{marginBottom: '0.5em',marginTop:'1em'}}>
					2. How many users are affected
				</Text>
				<ChoiceGroup
				onChange={this.onChange}
					options={[
					{
						key: 1,
						iconProps: { iconName: 'UserPause' },
						text: 'One'
					},
					{
						key: 2,
						iconProps: { iconName: 'PeoplePause' },
						text: 'Few'
					},
					{
						key: 3,
						iconProps: { iconName: 'Group' },
						text: 'Many',
					},
					{
						key: 4,
						iconProps: { iconName: 'AddGroup' },
						text: 'All',
					},
					]}
				/>
			</div>
		);
	}
}

export default Step1Impact;