import React, { Component } from 'react';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';


class Command extends Component {
	render() {
		return (
			<div>
				<CommandBar
					items={getItems}
					ariaLabel={'Use left and right arrow keys to navigate between commands'}
					/>
			</div>
		);
	}
}

const getItems = 
    [
      {
        key: 'new',
        name: 'New Ticket',
        iconProps: {
          iconName: 'Add'
        },
        onClick: () => console.log('new')
      }
    ]
  


export default Command;