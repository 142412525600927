import React, { Component } from 'react';
import { ChoiceGroup, IChoiceGroupOption } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { Text } from 'office-ui-fabric-react/lib/Text';

class Step1Impact extends Component {
	state = {
		selectedKey: 'bar'
	}
	onChange = (ev, option) => {
		this.setState({
			selectedKey: option.key
		});
		this.props.handleChange(2,option.key)
	}
	render() {
		return (
			<div>
				<Text variant="Large" block style={{marginBottom: '0.5em',marginTop:'1em'}}>
					3. How large is the organisation
				</Text>
				<ChoiceGroup
					onChange={this.onChange}
					options={[
					{
						key: 1,
						iconProps: { iconName: 'Home' },
						text: '1'
					},
					{
						key: 2,
						iconProps: { iconName: 'PageHeader' },
						text: '2-10'
					},
					{
						key: 3,
						iconProps: { iconName: 'CityNext2' },
						text: '10-100',
					},
					{
						key: 4,
						iconProps: { iconName: 'CityNext' },
						text: '100+',
					},
					]}
				/>
			</div>
		);
	}
}

export default Step1Impact;